<template>
  <v-row>
    <v-col cols="3">
      <v-btn color="success" @click="excel">
        <v-icon>
          mdi-file-excel
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import fileDownload from "js-file-download";

export default {
  name: "report-depreciation",
  data() {
    return {};
  },
  methods: {
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/depreciationExcel", this.params)
        .then(response => {
          fileDownload(response.data, `report-depreciation.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>
